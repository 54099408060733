import React, { createContext, useState, useEffect, useContext, useMemo } from "react";
import Authenticator from "../../services/Authenticator";
import { NotificationContext } from '../notification/Notification';

const UserContext = createContext(Authenticator.getUser());
const STATES = {
    PENDING: 'pending',
    LOGGED_IN: 'logged in',
    LOGGED_OUT: 'logged out',
};

export const UserProvider = ({ children, value }) => {
    const { notify } = useContext(NotificationContext);
    const [user, setUser] = useState(null);
    const [isClient, setIsClient] = useState(false);

    const getUserState = () => {
        if (!isClient) {
            return STATES.PENDING;
        }

        if (user === undefined) {
            return STATES.LOGGED_OUT;
        }

        if (typeof user === 'object' && user !== null) {
            return STATES.LOGGED_IN;
        }

        return STATES.PENDING;
    }

    const stateObj = useMemo(() => {
        const state = getUserState();

        let currentTokens = { DGNB: 0, PEPE: 0 }
        let tokensEarnedToDate = 0;
        let whitelistRequirements = {};

        try {
            currentTokens = JSON.parse(user?.currentTokens);
            tokensEarnedToDate = Object.keys(currentTokens).reduce((acc, val) => acc + parseInt(currentTokens?.[val]), 0)
            whitelistRequirements = JSON.parse(user?.whitelistRequirements);

            if (typeof whitelistRequirements === 'string') {
                whitelistRequirements = JSON.parse(whitelistRequirements);
            }
        } catch (error) {
            // return;
        }

        return {
            data: {
                emailAddress: user?.email,
                ethereumWallet: user?.ethereumWallet || null,
                solanaWallet: user?.solanaWallet || null,
                userRole: user?.userRole || 0,
                referral: user?.referral || null,
                referralCode: user?.referralCode || null,
                tiktokHandle: user?.tikTokHandle || null,
                warpcastHandle: user?.warpcastHandle || null,
                twitterHandle: user?.twitterHandle || null,
                instagramHandle: user?.instagramHandle || null,
                tokensEarned: tokensEarnedToDate || null,
                currentTokens: currentTokens || null,
                suggestedFloorPrice: user?.suggestedFloorPrice || null,
                charities: user?.charities,
                dgnbNftHolderAddress: user?.dgnbNftHolderAddress,
                whitelistRequirements: whitelistRequirements,
                intents: user?.intents
            },
            state,
            STATES
        };
    }, [user, isClient]);

    const onStateChange = (userData) => {
        if (userData && userData.user) {
            setUser(userData.user);
        } else {
            setUser(undefined);
        }
    };

    const onError = ({ message }) => {
        notify({
            message: message,
            type: 'critical'
        });

        setUser(undefined);
    };

    useEffect(() => {
        setIsClient(true);

        (async () => {
            await Authenticator.init();
        })();

        Authenticator.events.on(Authenticator.events.topics.STATE_CHANGE, onStateChange);
        Authenticator.events.on(Authenticator.events.topics.ERROR, onError);
        
        return () => {
            Authenticator.events.on(Authenticator.events.topics.STATE_CHANGE, onStateChange);
            Authenticator.events.on(Authenticator.events.topics.ERROR, onError);
        }
    }, []);

    return (
        <UserContext.Provider value={value || stateObj}>
            {children}
        </UserContext.Provider>
    )
};

export default UserContext;