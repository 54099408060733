import React from 'react';
import classnames from 'classnames';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './NotificationComponent.scss';

const NOTIFICATION_TYPES = {
    INFO: 'info',
    WARNING: 'warning',
    CRITICAL: 'critical',
    SUCCESS: 'success'
};

const AVAILABLE_THEMES = { LIGHT: 'light', DARK: 'dark' };

const Notification = ({ type, message, alwaysVisible, theme, handleClick }) => {
    const notificationTypeClass = classnames({
        info: type === NOTIFICATION_TYPES.INFO,
        warning: type === NOTIFICATION_TYPES.WARNING,
        critical: type === NOTIFICATION_TYPES.CRITICAL,
        success: type === NOTIFICATION_TYPES.SUCCESS
    }, 'notification');

    const notificationThemeClass = classnames({
        light: theme === AVAILABLE_THEMES.LIGHT,
        dark: theme === AVAILABLE_THEMES.DARK,
        info: type === NOTIFICATION_TYPES.INFO,
        warning: type === NOTIFICATION_TYPES.WARNING,
        critical: type === NOTIFICATION_TYPES.CRITICAL,
        success: type === NOTIFICATION_TYPES.SUCCESS
    }, 'toastNotificationContent');

    const getIcon = () => {
        if (type === NOTIFICATION_TYPES.WARNING || type === NOTIFICATION_TYPES.CRITICAL) {
            return (<i className="notificationIcon icon icon-crossmark" />);
        } else if (type === NOTIFICATION_TYPES.SUCCESS) {
            return (<i className="notificationIcon icon icon-checkmark-outline" />);
        } else if (type === NOTIFICATION_TYPES.INFO) {
            return (<i className="notificationIcon icon icon-info" />);
        }

        return null;
    };

    // eslint-disable-next-line react/prop-types
    const CloseButton = ({ closeToast }) => (
        <i
            className="icon icon-crossmark smaller"
            onClick={() => closeToast()}
        />
    );

    if (!message) {
        return null;
    }

    const expirationTime = alwaysVisible ? false : Math.min(Math.max((message.length || 1) * 50, 5000), 7000);

    const ToastMessage = () => (
        <div className='toastMessage'>
            {getIcon()}
            <span className='notificationMessage' dangerouslySetInnerHTML={{ __html: message }} />
        </div>
    );

    const toastComponent = toast(<ToastMessage />, {
        autoClose: expirationTime,
        className: notificationThemeClass,
        onClose: handleClick || ''
    });

    return (
        <div className={notificationTypeClass}>
            {toastComponent}
            <ToastContainer
                position="top-right"
                className='toastNotificationWrapper'
                closeOnClick={true}
                pauseOnFocusLoss
                transition={Slide}
                pauseOnHover
                autoClose={alwaysVisible ? false : true}
                closeButton={CloseButton}
            />
        </div>
    );
};

export default Notification;
